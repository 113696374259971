export default {
  props:['news'],
  name: "novelty",

  methods: {
    goToPage(e, news) {
      if(!e.target.closest('.novelty-text')) {
        this.$router.push({ name: 'article', params: { slug: news.slug } })
      }
    }
  }
}